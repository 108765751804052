
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Image, { StaticImageData } from 'next/image';
import classes from './index.module.scss';
import { P } from '@/components/common/Typography';
interface Props {
    companyLogo: StaticImageData;
    description: string;
    company: string;
}
export const DescriptionQuote: React.FunctionComponent<React.PropsWithChildren> = () => (<div className={classes.quote}>
    <svg width="59" height="45" viewBox="0 0 59 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.4419 45H34.2248V21.8098L58.4419 0V45ZM23.9419 45H3.24249e-05V21.8098L23.9419 0V45Z" fill="#697694" fillOpacity="0.15"/>
    </svg>
  </div>);
const OurClientsCarouselSlide: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ companyLogo, description, company }) => (<div className={classes.layout}>
    <div className={classes.imageWrapper}>
      <Image src={companyLogo} alt="" style={{
        maxWidth: '176px',
        maxHeight: '100px',
        objectFit: 'contain',
    }}/>
    </div>
    <div className={classes.contentLayout}>
      <div className={classes.descriptionContainer}>
        <DescriptionQuote />
        <P className={classes.description} disableMargin>
          {description}
        </P>
      </div>
      <div className={classes.company}>
        <span className={classes.companyLine}/>
        <P className={classes.companyText} disableMargin>
          {company}
        </P>
      </div>
    </div>
  </div>);
export default OurClientsCarouselSlide;

    async function __Next_Translate__getStaticProps__19174cc5af8__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/OurClients/components/OurClientsCarouselSlide/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc5af8__ as getStaticProps }
  