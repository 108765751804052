
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext, useState } from 'react';
import Article from '../OurClientsArticle';
import classes from './index.module.scss';
import ConfigsContext from '@/contexts/configs';
import Carousel from '@/components/Carousel';
const MediaAboutUsSection: React.FunctionComponent = () => {
    const { mediaAboutUs: { home }, } = useContext(ConfigsContext);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    if (home.length === 0)
        return null;
    return (<>
      <div className={classes.articelsLayout}>
        {home.map((data, idx) => (<Article data={data} key={idx}/>))}
      </div>
      <Carousel className={classes.carousel} currentSlideIndex={currentSlideIndex} hideSlideIndicators buttonsSettings={{
            md: 'bottom',
        }} pages={home.map((data, idx) => (<Article data={data} key={idx} disabledHover/>))} onSlideChange={(idx) => {
            setCurrentSlideIndex(idx);
        }} gtm="how-smsapi-works"/>
    </>);
};
export default MediaAboutUsSection;

    async function __Next_Translate__getStaticProps__19174cc4d93__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/OurClients/components/MediaAboutUsSection/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc4d93__ as getStaticProps }
  